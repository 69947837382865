import React, { useState, useEffect } from "react";
import FooterLink1 from "../../assets/images/footer_link1.png";
import FooterLink2 from "../../assets/images/footer_link2.png";
import FooterLink3 from "../../assets/images/footer_link3.png";
import FooterLink4 from "../../assets/images/footer_link4.png";
import FooterLink5 from "../../assets/images/footer_link5.png";
import FooterLink1Light from "../../assets/images/footer_link1_light.png";
import FooterLink2Light from "../../assets/images/footer_link2_light.png";
import FooterLink3Light from "../../assets/images/footer_link3_light.png";
import FooterLink4Light from "../../assets/images/footer_link4_light.png";
import FooterLink5Light from "../../assets/images/footer_link5_light.png";
import FooterLogoDark from "../../assets/images/logo.png";
import FooterLogoLight from "../../assets/images/logo_light.png";
import FooterMedium1Dark from "../../assets/images/footer_medium1_dark.png";
import FooterMedium2Dark from "../../assets/images/footer_medium2_dark.png";
import FooterMedium3Dark from "../../assets/images/footer_medium3_dark.png";
import FooterMedium4Dark from "../../assets/images/footer_medium4_dark.png";
import FooterMedium5Dark from "../../assets/images/footer_medium5_dark.png";
import FooterMedium1Light from "../../assets/images/footer_medium1_light.png";
import FooterMedium2Light from "../../assets/images/footer_medium2_light.png";
import FooterMedium3Light from "../../assets/images/footer_medium3_light.png";
import FooterMedium4Light from "../../assets/images/footer_medium4_light.png";
import FooterMedium5Light from "../../assets/images/footer_medium5_light.png";

import FooterUp from "../../assets/images/footer_up.png";
import FooterUpLight from "../../assets/images/footer_up_light.png";
import FooterDown from "../../assets/images/footer_down.png";
import FooterDownLight from "../../assets/images/footer_down_light.png";
import { useSelector, useDispatch } from "react-redux";
import { isDeriwHref } from "../../utils";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-use";
import "./index.scss";
import config from "../../config";

const Footer = props => {
  const rout = useLocation();
  const mode = useSelector(state => state.mode);
  const { t } = useTranslation();
  const history = useHistory();
  const below960 = useMedia("(max-width: 960px)");
  const [isFooter, setFooter] = useState(false)
  const [hideFooter,setHideFooter] = useState(false)

  useEffect(() => {
    // 控制某些页面隐藏footer
    setFooter(rout.pathname == '/' || rout.pathname == '/zh_cn')
    setHideFooter(rout.pathname.indexOf('/trade') > -1)
  }, [rout.pathname]);
  const navGoTo = h => {
    history.push(h);
  };
  const openPage = h => {
    window.open(h)
  }

  const footerClick = (id) => {
    history.push(`/home`);

    let interval = setInterval(() => {

      let anchorElement = document.getElementById(id);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
        clearInterval(interval)
      }
    }, 100);
  }

  const [tabM, setTabM] = useState(0)
  if (hideFooter) {
    return <></>
  }

  return (
    <div className={["footer_box"].join(' ')}>
      <div className="footer_content">
        {
          isFooter ?

            <div className="footer_top">

              <div className="footer_top_right">
                <img className="footer_logo" src={mode.mode == 'dark' ? FooterLogoDark : FooterLogoLight} />
                <div className="text1">DEX
                  {
                    below960 &&

                    <div className="text2">IS UNIQUE</div>
                  }
                </div>
                {
                  !below960 &&

                  <div className="text2">IS UNIQUE</div>
                }
                <div className="footer_link_box">
                  <a target="blank" href={config.telegram}><img src={mode.mode == 'dark' ? FooterMedium1Dark : FooterMedium1Light} /></a>
                  <a target="blank" href={config.telegramgb}><img src={mode.mode == 'dark' ? FooterMedium5Dark : FooterMedium5Light} /></a>
                  <a target="blank" href={config.twitter}><img src={mode.mode == 'dark' ? FooterMedium2Dark : FooterMedium2Light} /></a>
                  <a target="blank" href={config.discord}><img src={mode.mode == 'dark' ? FooterMedium3Dark : FooterMedium3Light} /></a>
                  <a target="blank" href={config.medium}><img src={mode.mode == 'dark' ? FooterMedium4Dark : FooterMedium4Light} /></a>
                </div>
              </div>
              <div className="footer_top_left">
                <div className="copy_right">CopyRight © 2017 - 2023 CoinW.com. All Rights Reserved.</div>
                <div className="footer_top_left_content">
                  <div className="footer_top_left_item" onClick={() => setTabM(tabM == 1 ? 0 : 1)}>{t('关于')}
                    {
                      below960 ?
                        <img src={
                          tabM == 1 ?
                            mode.mode == 'dark' ? FooterUp : FooterUpLight :
                            mode.mode == 'dark' ? FooterDown : FooterDownLight}
                        /> : ''
                    }
                  </div>
                  {
                    !below960 || tabM == 1 ?
                      <>
                        {/* <div className="footer_top_left_item" onClick={() => openPage(config.twitter)}>{t('社区')}</div> */}
                        {/* <div className="footer_top_left_item">{t('白皮书2')}</div> */}
                        <div className="footer_top_left_item">{t('服务条款')}</div>
                      </> : ''
                  }
                </div>
                <div className="footer_top_left_content">
                  <div className="footer_top_left_item" onClick={() => setTabM(tabM == 2 ? 0 : 2)}>{t('帮助')}
                    {
                      below960 ?
                        <img src={
                          tabM == 2 ?
                            mode.mode == 'dark' ? FooterUp : FooterUpLight :
                            mode.mode == 'dark' ? FooterDown : FooterDownLight}
                        /> : ''
                    }
                  </div>
                  {
                    !below960 || tabM == 2 ?
                      <>
                        {/* <div className="footer_top_left_item" onClick={() => openPage(config.telegram)}>{t('客户支持')}</div> */}
                        <div className="footer_top_left_item" onClick={() => openPage(isDeriwHref() ? config.deriwExplorer : config.explorer)}>{t('区块查询')}</div>
                        <div className="footer_top_left_item" onClick={() => navGoTo('/event-hub/bug-bounty')}>{t('故障赏金')}</div>
                      </> : ''
                  }
                </div>
                <div className="footer_top_left_content">
                  <div className="footer_top_left_item" onClick={() => setTabM(tabM == 3 ? 0 : 3)}>{t('开发者')}
                    {
                      below960 ?
                        <img src={
                          tabM == 3 ?
                            mode.mode == 'dark' ? FooterUp : FooterUpLight :
                            mode.mode == 'dark' ? FooterDown : FooterDownLight}
                        /> : ''
                    }
                  </div>
                  {
                    !below960 || tabM == 3 ?
                      <>
                        <div className="footer_top_left_item" onClick={() => openPage(config.auditReport)}>{t('Audit Report')}</div>
                        <div className="footer_top_left_item" onClick={() => openPage(config.github)}>Github</div>
                        <div className="footer_top_left_item" onClick={() => openPage(config.docs)}>{t('文档')}</div>
                        {/* <div className="footer_top_left_item">{t('故障赏金')}</div> */}
                      </> : ''
                  }
                </div>
              </div>
            </div> :
            <div className="footer_bot">
              <div className="footer_bot_link_box">
                <a className="footer_bot_link" target="blank" href={config.telegram}><img src={mode.mode == 'dark' ? FooterLink1 : FooterLink1Light} /></a>
                <a className="footer_bot_link" target="blank" href={config.telegramgb}><img src={mode.mode == 'dark' ? FooterLink5 : FooterLink5Light} /></a>
                <a className="footer_bot_link" target="blank" href={config.twitter}><img src={mode.mode == 'dark' ? FooterLink2 : FooterLink2Light} /></a>
                <a className="footer_bot_link" target="blank" href={config.discord}><img src={mode.mode == 'dark' ? FooterLink3 : FooterLink3Light} /></a>
                <a className="footer_bot_link" target="blank" href={config.medium}><img src={mode.mode == 'dark' ? FooterLink4 : FooterLink4Light} /></a>
              </div>
              <div className="footer_bot_text">
                {t('Trade, earn, and win crypto on the most popular decentralized platform in the galaxy.')}
              </div>
            </div>
        }
      </div>
    </div>
  );
};

export default Footer;
