import React, { useState, Fragment, useEffect } from "react";
import { Transition } from '@headlessui/react'
import ThemeLight from "../../assets/images/theme_dark.png";
import ThemeLightAct from "../../assets/images/theme_dark_act.png";
import ThemeDark from "../../assets/images/theme_light.png";
import ThemeDarkAct from "../../assets/images/theme_light_act.png";
import DeriwHide from "../../assets/images/deriw_hide.png";
import DeriwShow from "../../assets/images/deriw_show2.png";
import LangNew from "../../assets/images/lang_new.png";
import Theme from "../../assets/images/theme.png";
import SettingTwo from "../../assets/images/setting-two.png";
import CheckSmall from "../../assets/images/check-small.png";
import CheckSmall2 from "../../assets/images/check-small2.png";
import { APP_SETTING_LANGUAGE } from "../../config/localStorage";

import SettingModal from "../SettingModal";

import { SET_MODE } from "../../store/mode/action";
import { Languages } from "../../config/setting";
import { useSelector, useDispatch } from "react-redux";
import SettingImgDark from '../../assets/images/setting_img_dark.png'
import SettingImgLight from '../../assets/images/setting_img_light.png'
import i18next from "i18next";
import { useMedia } from "react-use";
import { Popover } from '@headlessui/react'
import "./index.scss";
import { Slippages } from "../../config/setting";
import useStorage, { toggleStorage } from "../../hooks/useStorage";
import { APP_SETTING_SLIPPAGE, APP_SETTING_TRADEVALIDTIME, APP_SETTING_THEME } from "../../config/localStorage";
import { useTranslation } from "react-i18next";


const Setting = props => {
  const { t } = useTranslation();
  const mode = useSelector(state => state.mode);
  const [isLangMode, setLangMode] = useState(false)

  const dispatch = useDispatch();
  const [storageData] = useStorage([APP_SETTING_SLIPPAGE, APP_SETTING_TRADEVALIDTIME, APP_SETTING_THEME, APP_SETTING_LANGUAGE])
  // slippage
  const [slippage, setSlippage] = useState("0.1")
  useEffect(() => {
    if (storageData[APP_SETTING_SLIPPAGE]) {
      setSlippage(storageData[APP_SETTING_SLIPPAGE] || "0.1")
    }
  }, [storageData[APP_SETTING_SLIPPAGE]])
  const onSetSlippage = (val) => {
    toggleStorage({
      id: APP_SETTING_SLIPPAGE,
      isShow: true,
      value: val
    })
  }

  // tradingValidTime
  const [tradeValidTime, setTradeValidTime] = useState("10") // minute
  useEffect(() => {
    if (storageData[APP_SETTING_TRADEVALIDTIME]) {
      setTradeValidTime(storageData[APP_SETTING_TRADEVALIDTIME] || "10")
    }
  }, [storageData[APP_SETTING_TRADEVALIDTIME]])
  const onSetTradeValidTime = (val) => {
    toggleStorage({
      id: APP_SETTING_TRADEVALIDTIME,
      isShow: true,
      value: val
    })
  }

  // theme
  const [theme, setTheme] = useState("white") // minute
  useEffect(() => {
    if (storageData[APP_SETTING_THEME]) {
      setTheme(storageData[APP_SETTING_THEME] || "10")
    }
  }, [storageData[APP_SETTING_THEME]])

  const onSetTheme = (val) => {
    toggleStorage({
      id: APP_SETTING_THEME,
      isShow: true,
      value: val
    })
  }

  const switchTheme = () => {
    localStorage.setItem(APP_SETTING_THEME, mode.mode === 'dark' ? 'light' : 'dark')
    dispatch({ type: SET_MODE.type, payload: { mode: mode.mode === 'dark' ? 'light' : 'dark' } });
    window.document.documentElement.setAttribute(
      "data-theme",
      mode.mode === 'dark' ? 'light' : 'dark'
    );
  }

  useEffect(() => {
    if (storageData[APP_SETTING_LANGUAGE]) {
      setLanguage(JSON.parse(storageData[APP_SETTING_LANGUAGE]) || Languages[0])
    }
  }, [storageData[APP_SETTING_LANGUAGE]])
  const [language, setLanguage] = useState(Languages[0])
  const onSetLanguage = (val) => {
    toggleStorage({
      id: APP_SETTING_LANGUAGE,
      isShow: true,
      value: JSON.stringify(val)
    })
    i18next.changeLanguage(val.key, () => { });
  }

  const [isSetingModal, setSettingModal] = useState(false)

  return <Popover className={["setting", "relative"].join(" ")}>
    {({ open }) => (
      <>
        {
          isSetingModal &&
          <SettingModal open={isSetingModal} onCancel={() => setSettingModal(false)} ></SettingModal>
        }
        <Popover.Button
          className={`lang_text_box`}
        >
          <img src={mode.mode == 'dark' ? SettingImgDark : SettingImgLight} className="lang_img" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="new_setting">
            <div className="new_setting_item" onClick={() => setLangMode(!isLangMode)}>
              <div className="new_setting_item_left_box">
                <img className="new_setting_item_left_img" src={LangNew} />{language.lang}
              </div>
              <img src={isLangMode ? DeriwShow : DeriwHide} className="right_hide" />
            </div>
            {
              isLangMode &&
              <div className="new_setting_item2">
                {
                  Languages.map((item) => (

                    <div className="new_setting_item2_text" key={item.lang}
                      onClick={e => onSetLanguage(item)}
                    >
                      {item.lang}

                      {

                        language.key === item.key && <img className="check" src={
                          // mode.mode == 'dark' ? CheckSmall : CheckSmall2
                          CheckSmall
                        } />
                      }</div>
                  ))
                }

              </div>
            }
            <div className="new_setting_item">
              <div className="new_setting_item_left_box">
                <img className="new_setting_item_left_img" src={Theme} />{t('界面设定')}
              </div>

              <div className="switch_theme">
                <img className="theme" onClick={switchTheme} src={mode.mode == 'dark' ? ThemeDarkAct : ThemeDark} />
                <img className="theme" onClick={switchTheme} src={mode.mode == 'light' ? ThemeLightAct : ThemeLight} />
              </div>
            </div>
            <div className="new_setting_item" onClick={() => setSettingModal(true)}>
              <div className="new_setting_item_left_box">
                <img className="new_setting_item_left_img" src={SettingTwo} />{t('Settings')}
              </div>
              <img src={DeriwHide} className="right_hide" />
            </div>


            {/* <div className={["setting_menu border-2 border-solid border-black shadow-[3px_3px_0px_rgba(0,0,0,1)]"].join(" ")}>
              <div className="setting_title">{t("Slippage")}</div>
              <div className="slippage_box">
                {
                  Slippages.map(item => (

                    <div className={["slippage_item", slippage === item.value && 'slippage_item_act'].join(' ')} onClick={() => onSetSlippage(item.value)} key={item.value}>{item.value}</div>
                  ))
                }
              </div>
              <div className="setting_title">{t("交易期限")}</div>
              <div className="setting_tran">
                <input type="number" onChange={(e) => onSetTradeValidTime(e.target.value)} value={tradeValidTime} /> {t("Minutes")}
              </div>
              <div className="setting_title setting_title2">
                {t("界面设定")}
                <div className="flex flex-row">
                  <img src={theme == "white" ? Sun : Sun} onClick={() => onSetTheme("white")} />
                  <img src={theme == "dark" ? Moon : Moon} onClick={() => onSetTheme("dark")} />
                </div>
              </div>
            </div> */}
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
};

export default Setting;
