import { ethers } from "ethers";
const { parseEther } = ethers.utils;

export default {
  networkId: 44474237230, // 1:主网, 3：Ropsten测试链, 128: 火币主网,256: 火币测试链 42：kovan测试
  baseUrl: "https://testgmxapi.weequan.cyou",
  testTransactionUrl: "https://dev.deriw.com/trading-prop/trade",
  deriwTransactionUrl: "https://www.deriw.com/trading-prop/trade",

  aboutFundPoolDetailUrl: "https://docs.deriw.com/liquidity/liquidity-on-v1",
  klineUrl: "https://chart.test.deriw.com/",
  duneUrl: "https://dune.com/deriw_com/deriw-analytics",
  wsUrl: "wss://testgmxapi.weequan.cyou/ws",
  explorer: "http://explorer.dev.deriw.com/",
  tx: 'http://explorer.dev.deriw.com/tx/',
  deriwExplorer: "https://explorer.deriw.com/",
  deriwTx: 'https://explorer.deriw.com/tx/',
  tokenAssetBaseUrl: "https://oss.deriw.com/tokens/",

  telegramgb: "https://t.me/deriwofficial",
  telegram: "https://t.me/deriwfinance",
  twitter: "https://twitter.com/DeriwFi",
  medium: "https://medium.com/@DeriwFi",
  discord: "https://discord.com/invite/deriwfinance",
  coinmarketcap: "https://coinmarketcap.com/community/profile/Deriw/",
  github: "https://github.com/deriwfi",
  docs: "https://docs.deriw.com",
  auditReport: "https://skynet.certik.com/projects/deriw-finance",

  sergentPrice: 99, // 100 usdt
  lieutenantPrice: 199, // 300 usdt
  chainName: 'Deriw Testnet3',
  SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0005"),
  netWorkIcon: "https://oss.deriw.com/chain/",
  shareBaseUrl: "https://fh-dex.oss-ap-southeast-1.aliyuncs.com/fh-dex/client/invitation/",

  ethRpc: "https://rpc.dev.deriw.com",

  mainChainAddr: "0x0000000000000000000000000000000000000000", // 主链币是：BSC
  // mainChainAddr: "0x21007fbfe34972C9B968a4AB1AB94AfdE9170244", // 主链币是：BSC

  address0: "0x0000000000000000000000000000000000000000",
  UnBindReferral: "0x0000000000000000000000000000000000000000000000000000000000000000", // 邀请解除绑定传的地址
  isRepeatCode: "0x0000000000000000000000000000000000000000", // 生成邀请码时校验 等于0地址 邀请码就不重复

  Vault: "0x5b88aA902e9d3B29089dc270303ADd075694187E",
  Router: "0xF7f4dF1e1D8bF22d993104AFbCD14bA8a0Ebc5D8",
  VaultUtils: "0x00c19508817541e1f5B1fAF1D327657688732Fa7",
  VaultReader: "0xc69f046c4626d4BDFC36021A2F3F5e8D95e358D0",
  Reader: "0x4b4f65412fcf535Ab758dD30c87606eC7976De32",
  GlpManager: "0xa80A698Ee4d61b82167f690cc2B247de8279F93f",
  GlpRewardRouter: "0xE3660EF72d74D7Db69409bdf32f827f56fFB5183",
  NATIVE_TOKEN: "0x0C69833AD4429fd69D11B84Bca45db8Ca44B3eDa", // (WETH)
  GLP: "0x48Da52fbAC98cc04230BD459F88FA58d65E24a2c",
  OrderBook: "0x2c4Ffe637e87D51Ae465986913Dfa9faCc56A761",
  OrderBookReader: "0x8Ff46a0F6C3741D9275332AA88dF95Db6134c814",

  PositionRouter: "0x5C96b24bcCa80f282668a7d605D74fc1b6f36121",
  ReferralStorage: "0x197d346c774454f1c55C64133Dc2Fb3c710D1db0",
  ReferralData: "0x46Eb1e71F466231E5DF57c5f0Bbe52c248eA5EFf",
  ReferralReader: "0x82C4d4aD24F69B265Bbe1a15aCf8aeeF53BA165c",
  Timelock: "0x41Bad19685C2C7E661B33D2d3Cdc4E10ac81f6Bf",

  Slippage: "0x6668De54dE315b51F0B6bC60C17d5EdDf730aC47",

  Phase: "0x8DaA8b31D51613e9C3AD1e7805DC5222Ca249091",

  AuthV2: "0xFE56A3E9b3a188e7a414fdBE394D17D14f0290F9",
  FoundFactoryV2: "0xebF2f134bf206608894ca08f210DE31312D363b6",
  PoolDataV2: "0x83763FE16886eE8A4b536E1BBFC2226db4875cD1",
  LpCounter: "0x00C5841F57104845B035065fE8bEB5e8A669c0c2",
  ErrorContractV2: "0x2995dF062BdD06F760E1aD00b88AD26CF463bec0",
  FoundRouterV2: "0x96a92a229770Be41fAc875d253D6a0428f17b45E",
  FoundReader: "0x8c97fCfdb806Af13788F4b8b314B52c91fbed95c",

  TestAuth: "0x624d2A9561DD68Fd97561AEbE503B4CA8B506DAB",
  TestPrpoFactory: "0xA2E1E977a9Af7Cac1D045529128501979d6BD83F",
  TestPropTradeData: "0xdcB21F3FbD087b0574CBc3C947E6AEaeA25dEbd2",
  TestErrContract: "0xE0CFc0f7Cd1c2Ec7C1C5267D172e99B99CfE8942",
  TestPropTradeRouter: "0xDe9d9F49452e4BFead750cA979438aDfB18e1B87",

  fundMinDeposit: {
    USDT: 10,
    ETH: 0.005,
    BNB: 0.044,
    UNI: 2,
    LINK: 1,
    UNISWAP: 2,
    WBTC: 0.0003
  }
};
