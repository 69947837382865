import React, { useState, useEffect } from "react";
import CModal2 from "../common/CModal2";
import "./index.scss";
import { Slippages } from "../../config/setting";
import { APP_SETTING_SLIPPAGE, APP_SETTING_TRADEVALIDTIME, APP_SETTING_THEME, APP_SETTING_LANGUAGE } from "../../config/localStorage";
import { useTranslation } from "react-i18next";
import useStorage, { toggleStorage } from "../../hooks/useStorage";

const SettingModal = ({ open, onCancel }) => {
  const { t } = useTranslation();

  const [storageData] = useStorage([APP_SETTING_SLIPPAGE, APP_SETTING_TRADEVALIDTIME, APP_SETTING_THEME, APP_SETTING_LANGUAGE])
  const [tradeValidTime, setTradeValidTime] = useState("30") // minute
  useEffect(() => {
    if (storageData[APP_SETTING_TRADEVALIDTIME]) {
      setTradeValidTime(storageData[APP_SETTING_TRADEVALIDTIME] || "30")
    }
  }, [storageData[APP_SETTING_TRADEVALIDTIME]])

  const onSetTradeValidTime = (val) => {
    toggleStorage({
      id: APP_SETTING_TRADEVALIDTIME,
      isShow: true,
      value: val
    })
  }

  const [slippage, setSlippage] = useState("0.1")
  useEffect(() => {
    if (storageData[APP_SETTING_SLIPPAGE]) {
      setSlippage(storageData[APP_SETTING_SLIPPAGE] || "0.1")
    }
  }, [storageData[APP_SETTING_SLIPPAGE]])
  const onSetSlippage = (val) => {
    toggleStorage({
      id: APP_SETTING_SLIPPAGE,
      isShow: true,
      value: val
    })
  }

  const confirm = () => {

    if (tradeValidTime) {
      toggleStorage({
        id: APP_SETTING_TRADEVALIDTIME,
        isShow: true,
        value: tradeValidTime
      })
    }
    if (slippage) {
      toggleStorage({
        id: APP_SETTING_SLIPPAGE,
        isShow: true,
        value: slippage
      })
    }
    onCancel()
  }
  return (
    <CModal2
      onCancel={onCancel}
      visible={open}
      title={t("Settings")}
    >
      <div className="setting_modal">
        <div className="setting_modal_lable">{t("滑点")}</div>
        <div className="hd_box">
          {
            Slippages.map(item => (
              <div className={["hd_item", slippage === item.value && 'hd_item_act'].join(' ')} key={item.value}
                onClick={() => setSlippage(item.value)}
              >{item.value}</div>
            ))
          }
        </div>
        <div className="setting_modal_lable">{t("交易截止时间")}</div>
        <div className="hd_box">
          <input
            onChange={(e) => {
              var regex = /^[\d]*\.?[\d]*$/;
              let value = e.target.value
              if (!regex.test(value)) {
                // 不是小数直接return
                return
              }
              if (value.length == 1 && value == '.') {
                return
              }
              if (value.indexOf('.') == -1 && value != 0) {
                value = Number(value)
              }
              if (Number(value) > 999) {
                return
              }
              setTradeValidTime(value)
            }}
          //  onChange={(e) => onSetTradeValidTime(e.target.value)}
           value={tradeValidTime} />
          <div className="dot"> {t("Minutes")}</div>
        </div>
        <div className="btn" onClick={confirm}>{t('确认')}</div>
      </div>
    </CModal2>
  );
};

export default SettingModal;
